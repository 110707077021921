"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Modal {
    constructor() {
        Object.defineProperty(this, "modalContainer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "body", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.modalContainer = null;
        this.body = document.body;
        this.isOpen = false;
        this.handleEvents();
    }
    createModalContent(url) {
        return `
            <div class="modularity-modal-wrapper">
                <button class="modularity-modal-close" data-modularity-modal-action="close">&times; ${modularityAdminLanguage.close}</button>
                <div class="modularity-modal-spinner-container" id="modularity-iframe-loader"><span class="modularity-modal-spinner"></span></div>
                <iframe class="modularity-modal-iframe" src="${url}" frameborder="0" allowtransparency></iframe>
            </div>
        `;
    }
    open(url) {
        this.modalContainer = document.createElement('div');
        this.modalContainer.id = 'modularity-modal';
        this.modalContainer.innerHTML = this.createModalContent(url);
        this.body.appendChild(this.modalContainer);
        this.body.classList.add('modularity-modal-open');
        this.isOpen = true;
    }
    close() {
        if (this.modalContainer) {
            this.body.removeChild(this.modalContainer);
            this.body.classList.remove('modularity-modal-open');
            this.isOpen = false;
        }
    }
    handleEvents() {
        this.body.addEventListener('click', (e) => {
            const target = e.target;
            if (target && target.getAttribute('data-modularity-modal-action') === 'close') {
                e.preventDefault();
                this.close();
            }
        });
    }
}
exports.default = Modal;
